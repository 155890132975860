import React, { cloneElement, useState, ReactElement, FormEvent } from "react";

type FormDataPayload = {
  [key: string]: string | File; // Adjust the value type as needed
};

type Cf7FormWrapperProps = {
  children: ReactElement;
  formId: string;
};

const jsonToFormData = (json: FormDataPayload): FormData | null => {
  try {
    const data = new FormData();

    for (let k in json) {
      data.append(k, json[k]);
    }

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const ErrorMessage = () => {
  return (
    <div style={{ color: "red" }}>
      <strong>formId</strong> are mandatory attributes
    </div>
  );
};

const Cf7FormWrapper = ({
  children,
  formId,

}: Cf7FormWrapperProps) => {
  const [isSent, setSent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState<string | null>(null);

  const captchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
  const siteUrl1 = process.env.NEXT_PUBLIC_WORDPRESS_SITE_URL;
  const apiUrl = `${siteUrl1}/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback/`;

  const formSubmitHandler = (event: FormEvent<HTMLFormElement>, payload: FormDataPayload) => {
    event.preventDefault();

    setLoading(true);
    setError(null);

    fetch(apiUrl, {
      method: "POST",
      body: jsonToFormData(payload),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status !== "mail_sent") throw resp.message;
        setSent(true);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const Form = cloneElement(children, {
    handler: formSubmitHandler,
    isLoading,
    isSent,
    hasError,
    captchaKey
  });

  return <div>{ formId ? Form : <ErrorMessage />}</div>;
};

export default Cf7FormWrapper;
