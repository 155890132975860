import React, {useState, useEffect} from 'react'
import { useRef } from 'react';
import Cf7FormWrapper from '@/components/cf7-form-wrapper';
import ReCAPTCHA from 'react-google-recaptcha';
function Form({ handler, isLoading, isSent, hasError, captchaKey }:any) {
    const initialFormState = {
    'first-name': '',
    'last-name': '',
    'your-email': '',
    };

    const [formState, setFormState] = useState(initialFormState);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [gReCaptchaToken, setGReCaptchaToken] = useState<string | null>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const handleFieldChange = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
    setFormState({
        ...formState,
        [field]: e.target.value,
    });
    };
    const handleCaptchaChange = (token: string | null) => {
      setGReCaptchaToken(token);
    };
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      
        const isEmpty = Object.values(formState).some((value) => value === '');
        if (isEmpty) {
          setShowEmptyError(true);
          setSubmitSuccess(false);
          return;
        }

         //   if (!gReCaptchaToken) {
          //     alert('Please complete the reCAPTCHA.');
          //     return;
          //   }
  
      
        setShowEmptyError(false);
        handler(e, formState);
      
        // Reset form fields after successful submission
        setSubmitSuccess(true);
      
        // Reset form fields using the form reference
        if (formRef.current) {
          formRef.current.reset();
          setFormState(initialFormState);
          
          setGReCaptchaToken(null);
          // Reset the reCAPTCHA component using its ref
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }
        
      };
      
  
    return (
        <>
        
            <form  ref={formRef} onSubmit={handleFormSubmit} className=''>
              <div className="row">
                 <div className="form-group col">
                    <input type="text" placeholder='First Name' name="first-name" onChange={(e) => handleFieldChange("first-name", e)} />
                    {showEmptyError && formState['first-name'] === '' && <p className='error'>First name is required</p>}
                </div>
                 <div className="form-group col">
                    <input type="text" placeholder='Last Name' name="last-name" onChange={(e) => handleFieldChange("last-name", e)} />
                    {showEmptyError && formState['last-name'] === '' && <p className='error'>Last name is required</p>}
                </div>
                 <div className="form-group col">
                    <input type="email" placeholder='Email Address' name="your-email" onChange={(e) => handleFieldChange("your-email", e)}/>
                    {showEmptyError && formState['your-email'] === '' && <p className='error'>Email address is required</p>}
                </div>
                 <div className="form-group col-auto">
                    <input type="submit" value='Sign Up' />
                </div>
              </div>

              <ReCAPTCHA size="invisible" ref={recaptchaRef} sitekey={captchaKey} onChange={handleCaptchaChange} />
              
            </form>
            <div className="success_message">
                {isLoading &&  
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                }
                {isSent && submitSuccess &&  <div className="alert-success text-success" role="alert"><strong>Thank you for your subscription.</strong></div>}
            </div>
        </>
    )
  }

function SignUpForm() {
  return (
    <Cf7FormWrapper formId="848">
        <Form/>
    </Cf7FormWrapper>
  )
}

export default SignUpForm
